import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Review = () => {
  const [name, setName] = useState('');
  const [trackingId, setTrackingId] = useState('');
  const [description, setDescription] = useState('');
  const [reviews, setReviews] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch all reviews when the component mounts
    const fetchReviews = async () => {
      try {
        const response = await axios.get('https://api.fastpost.ca/api/reviews');
        setReviews(response.data);
      } catch (error) {
        console.error('Failed to fetch reviews', error);
        setError('Failed to fetch reviews');
      }
    };

    fetchReviews();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);

    try {
      const response = await axios.post('https://api.fastpost.ca/api/reviews', {
        name,
        trackingId,
        description,
      });
      // Add the new review to the local state to update the list without refetching
      setReviews([...reviews, response.data]);
      // Clear the form fields
      setName('');
      setTrackingId('');
      setDescription('');
    } catch (error) {
      console.error('Failed to submit review', error);
      setError('Failed to submit review');
    }
  };

  return (
    <div className="Review">
      <h1>Service Reviews</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Tracking ID"
          value={trackingId}
          onChange={(e) => setTrackingId(e.target.value)}
        />
        <textarea
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <button type="submit">Submit Review</button>
      </form>

      {error && <div className="Review-error">{error}</div>}

      <div className="Review-list">
        {reviews.map((review) => (
          <div key={review._id}>
            <h3>{review.name}</h3>
            <p>{review.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Review;