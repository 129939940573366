import React, { useState } from 'react';
import './Home.css';
import RequestTable from '../../components/RequestTable/RequestTable.tsx';
import LiveChat from '../../components/LiveChat/LiveChat.tsx';

const Home: React.FC = () => {
  return (
    <div className='HomePage'>
      <div className='HomeBox'>
        <div className='overlay'>
          <RequestTable />
          <LiveChat />
        </div>
      </div>
    </div>
  );
};

export default Home;