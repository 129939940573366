import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header.tsx';
import Track from './containers/Track/Track.tsx';
import Admin from './containers/Admin/Admin.tsx';
import Login from './containers/Login/Login.tsx';
import Register from './containers/Register/Register.tsx';
import Home from './containers/Home/Home.tsx';
import Payment from './containers/Payment/payment.tsx';
import UserDashboard from './containers/UserDashboard/UserDashboard.tsx';
import Review from './containers/Review/Review.tsx';

const App: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <main className="App-content">
        <Routes>
          <Route path="/login" element={<Login />} /> {}
          <Route path="/register" element={<Register/>}/>
          <Route path="/dashboard" element={<UserDashboard/>}/>
          <Route path="/admin" element={<Admin />} />
          <Route path="/track" element={<Track />} />
          <Route path="/payment" element={<Payment/>}/>
          <Route path="/review" element={<Review/>}/>
          <Route path="/" element={<Home />} />
        </Routes>
      </main>
    </div>
  );
};

export default App;