import React, { useState, useEffect, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import './RequestTable.css';

const RequestTable: React.FC = () => {
    const [fromAddress, setFromAddress] = useState('');
    const [toAddress, setToAddress] = useState('');
    const [packageWeight, setPackageWeight] = useState('');
    const [requestChoice, setRequestChoice] = useState('delivery');
    const [packageSize, setPackageSize] = useState('');
    const [distance, setDistance] = useState(0);
    const [date, setDate] = useState('');
    const navigate = useNavigate();

    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ]
    function addDays(days: number) {
        var result = new Date();
        result.setDate(result.getDate() + days);
        return result;
      }

    const calculateDate = () => {
        return Math.floor(distance/10) + (packageSize === 'large' ? 3 : packageSize === 'medium' ? 2 : 0) + 1;
    };

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        navigate("/payment", {
            state: {
                fromAddress,
                toAddress,
                packageWeight,
                packageSize,
                distance,
                date,
                requestChoice
            }
        });
    };

    useEffect(() => {
        const estimateDate = addDays(calculateDate());
        setDate(`${months[estimateDate.getMonth()]} ${estimateDate.getDay() + 1}, ${estimateDate.getFullYear()}`);
      }, [distance, packageSize]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setDistance(parseFloat((Math.random() * 200).toFixed(2)))
          }, 1000);
        return () => clearTimeout(delayDebounceFn);
      }, [fromAddress, toAddress]);

    return (
    <form className='RequestTable' onSubmit={handleSubmit}>
        <div className='ChoiceButton'>
            <button
                type="button"
                className={requestChoice === 'quote' ? 'ActiveButton' : 'InactiveButton'}
                onClick={() => setRequestChoice('quote')}
            >Regular</button>
            <button
                type="button"
                className={requestChoice === 'delivery' ? 'ActiveButton' : 'InactiveButton'}
                onClick={() => setRequestChoice('delivery')}
            >Express</button>
            </div>
            <div className='InformationTable'>
                <div className='InformationFields'> 
                    <div className='InputBox'>
                        <div className='InputField'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6415 16.2445C18.0423 14.3001 19 12.1631 19 10C19 6.13401 15.866 3 12 3C8.13401 3 5 6.13401 5 10C5 12.1631 5.95769 14.3001 7.35846 16.2445C8.74868 18.1742 10.4581 19.7503 11.6769 20.7485C11.8768 20.9122 12.1232 20.9122 12.3231 20.7485C13.5419 19.7503 15.2513 18.1742 16.6415 16.2445ZM13.5904 22.2957C16.1746 20.1791 21 15.4917 21 10C21 5.02944 16.9706 1 12 1C7.02944 1 3 5.02944 3 10C3 15.4917 7.82537 20.1791 10.4096 22.2957C11.3466 23.0631 12.6534 23.0631 13.5904 22.2957Z" fill="#979C9E"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8ZM8 10C8 7.79086 9.79086 6 12 6C14.2091 6 16 7.79086 16 10C16 12.2091 14.2091 14 12 14C9.79086 14 8 12.2091 8 10Z" fill="#979C9E"/>
                            </svg>
                            <div className='FromAddressText'>
                                <input type='text' placeholder='123 John’s House' value={fromAddress} onChange={e => setFromAddress(e.target.value)} required/>
                            </div>
                        </div>                    
                    </div>
                    <div className='InputBox'>
                        <div className='InputField'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.2528 2.47775C20.3134 1.61917 22.3806 3.68639 21.522 5.74698L15.5985 19.9634C14.4401 22.7437 10.3653 22.2738 9.8701 19.3028L9.13109 14.8687L4.69701 14.1297C1.72602 13.6345 1.25608 9.55972 4.03636 8.40127L18.2528 2.47775ZM19.6759 4.97775C19.8476 4.56563 19.4341 4.15219 19.022 4.3239L4.80559 10.2474C3.87883 10.6336 4.03548 11.9918 5.0258 12.1569L9.45989 12.8959C10.3028 13.0364 10.9634 13.697 11.1039 14.5399L11.8429 18.974C12.0079 19.9643 13.3662 20.121 13.7524 19.1942L19.6759 4.97775Z" fill="#979C9E"/>
                            </svg>
                            <div className='FromAddressText'>
                                <input type='text' placeholder='Concordia Unversity' value={toAddress} onChange={e => setToAddress(e.target.value)} required/>
                            </div>
                        </div>                    
                    </div>
                    <div className='InputBox'>
                        <div className='InputField'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.29289 13.2929C2.68342 12.9024 3.31658 12.9024 3.70711 13.2929L7 16.5858C7.39052 16.9763 7.39052 17.6095 7 18C6.60948 18.3905 5.97631 18.3905 5.58579 18L2.29289 14.7071C1.90237 14.3166 1.90237 13.6834 2.29289 13.2929Z" fill="#979C9E"/>
                                <path d="M21.7348 5.67829C22.1094 5.27246 22.084 4.63981 21.6782 4.2652C21.2724 3.8906 20.6397 3.9159 20.2651 4.32172L8.94342 16.5869C8.56882 16.9927 8.59413 17.6254 8.99995 18C9.40577 18.3746 10.0384 18.3493 10.413 17.9435L17.3158 10.4654C17.5085 10.2568 17.836 10.2502 18.0368 10.451L19.2929 11.7071C19.6834 12.0976 20.3166 12.0976 20.7071 11.7071C21.0976 11.3166 21.0976 10.6834 20.7071 10.2929L19.3662 8.95195C19.1764 8.76219 19.1703 8.45646 19.3523 8.25926L21.7348 5.67829Z" fill="#777E91"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.87871 12.8782L4.12136 11.1209C2.94978 9.9493 2.94978 8.0498 4.12135 6.87823L7.87872 3.12087C9.05029 1.94929 10.9498 1.9493 12.1214 3.12087L13.8787 4.87823C15.0503 6.0498 15.0503 7.9493 13.8787 9.12087L10.1214 12.8782C8.94978 14.0498 7.05029 14.0498 5.87871 12.8782ZM5.53557 9.70666L7.29293 11.464C7.68345 11.8545 8.31662 11.8545 8.70714 11.464L12.4645 7.70666C12.855 7.31613 12.855 6.68296 12.4645 6.29244L10.7071 4.53508C10.3166 4.14456 9.68345 4.14456 9.29293 4.53508L5.53557 8.29244C5.14504 8.68296 5.14504 9.31613 5.53557 9.70666Z" fill="#777E91"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8 20C8.55228 20 9 19.5523 9 19C9 18.4477 8.55228 18 8 18C7.44772 18 7 18.4477 7 19C7 19.5523 7.44772 20 8 20ZM8 22C9.65685 22 11 20.6569 11 19C11 17.3431 9.65685 16 8 16C6.34315 16 5 17.3431 5 19C5 20.6569 6.34315 22 8 22Z" fill="#777E91"/>
                            </svg>
                            <div className='WeigthText'>
                                <input
                                    type='text'
                                    placeholder='6.43'
                                    value={packageWeight}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (inputValue.length <= 6 && (/^\d+[.,]?\d*$/).test(inputValue) || inputValue === "") {
                                            setPackageWeight(inputValue);
                                        }
                                    }}
                                    required
                                />
                                <label className='UnitLabel'>(kg)</label>
                            </div>
                        </div>                    
                    </div>
                    <div className='InputBox'>
                        <div className='InputField'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M20 13H13.5C13.2239 13 13 13.2239 13 13.5V20.5C13 20.7761 13.2239 21 13.5 21H20C20.5523 21 21 20.5523 21 20V14C21 13.4477 20.5523 13 20 13ZM11 11V23H20C21.6569 23 23 21.6569 23 20V14C23 12.3431 21.6569 11 20 11H11Z" fill="#979C9E"/>
                                <path d="M16 11H18V14C18 14.5523 17.5523 15 17 15C16.4477 15 16 14.5523 16 14V11Z" fill="#777E91"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11 13.5C11 13.2239 10.7761 13 10.5 13H4C3.44772 13 3 13.4477 3 14V20C3 20.5523 3.44772 21 4 21H10.5C10.7761 21 11 20.7761 11 20.5V13.5ZM4 11C2.34315 11 1 12.3431 1 14V20C1 21.6569 2.34315 23 4 23H13V11H4Z" fill="#777E91"/>
                                <path d="M6 11H8V14C8 14.5523 7.55228 15 7 15C6.44772 15 6 14.5523 6 14V11Z" fill="#777E91"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15 3H9C8.44772 3 8 3.44772 8 4V11H16V4C16 3.44772 15.5523 3 15 3ZM9 1C7.34315 1 6 2.34315 6 4V13H18V4C18 2.34315 16.6569 1 15 1H9Z" fill="#777E91"/>
                                <path d="M11 1H13V4C13 4.55228 12.5523 5 12 5C11.4477 5 11 4.55228 11 4V1Z" fill="#777E91"/>
                            </svg>
                            <div className='SizeButtons'>
                                <div className='LargeSize' onClick={() => setPackageSize('large')}>
                                    <button type="button" className={packageSize === "large" ? "ActiveSizeButton" : 'SizeButton'}>Large</button>
                                    <label className='SizeLabel'>(size)</label>
                                </div>
                                |
                                <div className='MediumSize' onClick={() => setPackageSize('medium')}>
                                    <button type="button" className={packageSize === "medium" ? "ActiveSizeButton" : 'SizeButton'}>Medium</button>
                                    <label className='SizeLabel'>(size)</label>
                                </div>
                                |
                                <div className='SmallSize' onClick={() => setPackageSize('small')}>
                                    <button type="button" className={packageSize === "small" ? "ActiveSizeButton" : 'SizeButton'}>Small</button>
                                    <label className='SizeLabel'>(size)</label>
                                </div>
                            </div>
                        </div>                    
                    </div>
                </div>
            </div>
            <div className='MetricTable'>
                <div className='DistanceTable'>
                    <label className='DistanceLabel'>Distance</label>
                    <text className='Distance' >{distance} km</text>
                </div>
                <div className='ArriveTable'>
                    <label className='ArriveLabel'>Arrive Before</label>
                    <text className='ArriveDate'>{date}</text>
                </div>
            </div>
            <button type="submit" className='SubmitButton'>Submit</button>
    </form>
    );
};

export default RequestTable;