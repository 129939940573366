import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { nanoid } from 'nanoid';
import './Admin.css';
import LiveChat from '../../components/LiveChat/LiveChat.tsx';

interface Shipment {
  _id: string;
  trackingId: string;
  senderId: string;
  recipientId: string;
  origin: string;
  destination: string;
  deliveryStatus: string;
  estimatedDeliveryDate: string;
  actualDeliveryDate: string;
  sender?: string;
  recipient?: string;
}

interface User {
  _id: string;
  name: string;
  email: string;
  password: string;
  isAdmin: boolean;
}

const Admin: React.FC = () => {
  const [shipments, setShipments] = useState<Shipment[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const usersMap: Map<string, User> = new Map();

  const [newUserName, setNewUserName] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserPassword, setNewUserPassword] = useState('');
  const [newUserIsAdmin, setNewUserIsAdmin] = useState(false);

  const [newShipmentTrackingId, setNewShipmentTrackingId] = useState('');
  const [newShipmentSenderId, setNewShipmentSenderId] = useState('');
  const [newShipmentRecipientId, setNewShipmentRecipientId] = useState('');
  const [newShipmentOrigin, setNewShipmentOrigin] = useState('');
  const [newShipmentDestination, setNewShipmentDestination] = useState('');
  const [newShipmentDeliveryStatus, setNewShipmentDeliveryStatus] = useState('In Transit');
  const [newShipmentEstimatedDeliveryDate, setNewShipmentEstimatedDeliveryDate] = useState('');
  const [newShipmentActualDeliveryDate, setNewShipmentActualDeliveryDate] = useState('');  

  useEffect(() => {
    const fetchAdminData = async () => {
      const token = localStorage.getItem('token');
  
      try {
        const shipmentsResponse = await axios.get('https://api.fastpost.ca/api/shipments', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const usersResponse = await axios.get('https://api.fastpost.ca/api/users', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const usersMap: Map<string, User> = new Map(usersResponse.data.map((user: User) => [user._id, user]));
  
        const enrichedShipments = shipmentsResponse.data.map((shipment: Shipment) => {
          const senderUser = usersMap.get(shipment.senderId);
          const recipientUser = usersMap.get(shipment.recipientId);
          return {
            ...shipment,
            sender: senderUser ? senderUser.name : 'Unknown Sender',
            recipient: recipientUser ? recipientUser.name : 'Unknown Recipient',
          };
        });
  
        setShipments(enrichedShipments);
        setUsers(usersResponse.data);
      } catch (error) {
        console.error('Failed to fetch admin data', error);
      }
    };
  
    fetchAdminData();
  }, []);

  const generateUniqueId = () => {
    return nanoid();
  };

  const createUser = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post('https://api.fastpost.ca/api/register', {
        name: newUserName,
        email: newUserEmail,
        password: newUserPassword,
        isAdmin: newUserIsAdmin,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers([...users, response.data]);

      setNewUserName('');
      setNewUserEmail('');
      setNewUserPassword('');
      setNewUserIsAdmin(false);
    } catch (error) {
      console.error('Failed to create user', error);
    }
  };

  const createShipment = async () => {
    const token = localStorage.getItem('token');
    const trackingId = generateUniqueId();
    try {
      const response = await axios.post('https://api.fastpost.ca/api/shipments', {
        trackingId,
        senderId: newShipmentSenderId,
        recipientId: newShipmentRecipientId,
        origin: newShipmentOrigin,
        destination: newShipmentDestination,
        deliveryStatus: newShipmentDeliveryStatus,
        estimatedDeliveryDate: newShipmentEstimatedDeliveryDate,
        actualDeliveryDate: newShipmentActualDeliveryDate,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setShipments([...shipments, response.data]);
      setNewShipmentSenderId('');
      setNewShipmentRecipientId('');
      setNewShipmentOrigin('');
      setNewShipmentDestination('');
      setNewShipmentDeliveryStatus('');
      setNewShipmentEstimatedDeliveryDate('');
      setNewShipmentActualDeliveryDate('');
    } catch (error) {
      console.error('Failed to create shipment', error);
    }
  };

  const updateShipment = async (id: string, updatedShipment: Shipment) => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.put(`https://api.fastpost.ca/api/shipments/${id}`, updatedShipment, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setShipments(shipments.map(shipment => shipment._id === id ? response.data : shipment));
    } catch (error) {
      console.error('Failed to update shipment', error);
    }
  };

  const deleteShipment = async (id: string) => {
    const token = localStorage.getItem('token');
  
    try {
      await axios.delete(`https://api.fastpost.ca/api/shipments/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      setShipments(shipments.filter(shipment => shipment._id !== id));
    } catch (error) {
      console.error('Failed to delete shipment', error);
    }
  };

  const deleteUser = async (id: string) => {
    const token = localStorage.getItem('token');

    try {
      await axios.delete(`https://api.fastpost.ca/api/users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setUsers(users.filter(user => user._id !== id));
    } catch (error) {
      console.error('Failed to delete user', error);
    }
  };

  const renderUserOptions = () => {
    return users.map((user) => (
      <option key={user._id} value={user._id}>
        {user.name} ({user._id})
      </option>
    ));
  };

  return (
    <div className="Admin">
      <h1>Admin Dashboard</h1>
  
      <h2>Shipments</h2>
      <table>
        <thead>
          <tr>
            <th>Tracking ID</th>
            <th>Sender</th>
            <th>Recipient</th>
            <th>Origin</th>
            <th>Destination</th>
            <th>Delivery Status</th>
            <th>Estimated Delivery Date</th>
            <th>Actual Delivery Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {shipments.map(shipment => (
            <tr key={shipment._id}>
              <td>{shipment.trackingId}</td>
              <td>{shipment.sender}</td>
              <td>{shipment.recipient}</td>
              <td>{shipment.origin}</td>
              <td>{shipment.destination}</td>
              <td>{shipment.deliveryStatus}</td>
              <td>{shipment.estimatedDeliveryDate}</td>
              <td>{shipment.actualDeliveryDate}</td>
              <td>
                <button onClick={() => deleteShipment(shipment._id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
  
      <h2>Users</h2>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Is Admin</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user._id}>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.isAdmin ? 'Yes' : 'No'}</td>
              <td>
                <button onClick={() => deleteUser(user._id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div>
        <h2>Create New User</h2>
        <input type="text" value={newUserName} onChange={(e) => setNewUserName(e.target.value)} placeholder="Name" />
        <input type="email" value={newUserEmail} onChange={(e) => setNewUserEmail(e.target.value)} placeholder="Email" />
        <input type="password" value={newUserPassword} onChange={(e) => setNewUserPassword(e.target.value)} placeholder="Password" />
        <label>
          <input type="checkbox" checked={newUserIsAdmin} onChange={(e) => setNewUserIsAdmin(e.target.checked)} />
          Is Admin?
        </label>
        <button onClick={createUser}>Create User</button>
      </div>

      <div>
        <h2>Create New Shipment</h2>
        <select
          value={newShipmentSenderId}
          onChange={(e) => setNewShipmentSenderId(e.target.value)}
        >
          <option value="">Select Sender</option>
          {renderUserOptions()}
        </select>
        <select
          value={newShipmentRecipientId}
          onChange={(e) => setNewShipmentRecipientId(e.target.value)}
        >
          <option value="">Select Recipient</option>
          {renderUserOptions()}
        </select>
        <input
          type="text"
          value={newShipmentOrigin}
          onChange={(e) => setNewShipmentOrigin(e.target.value)}
          placeholder="Origin"
        />
        <input
          type="text"
          value={newShipmentDestination}
          onChange={(e) => setNewShipmentDestination(e.target.value)}
          placeholder="Destination"
        />
        <select
          value={newShipmentDeliveryStatus}
          onChange={(e) => setNewShipmentDeliveryStatus(e.target.value)}
        >
          <option value="In Transit">In Transit</option>
          <option value="Delivered">Delivered</option>
          <option value="Cancelled">Cancelled</option>
        </select>
        <input
          type="date"
          value={newShipmentEstimatedDeliveryDate}
          onChange={(e) => setNewShipmentEstimatedDeliveryDate(e.target.value)}
          placeholder="Estimated Delivery Date"
        />
        <input
          type="date"
          value={newShipmentActualDeliveryDate}
          onChange={(e) => setNewShipmentActualDeliveryDate(e.target.value)}
          placeholder="Actual Delivery Date"
        />
        <button onClick={createShipment}>Create Shipment</button>
      </div>

      <LiveChat />

    </div>
  );
};

export default Admin;