import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './UserDashboard.css';
import LiveChat from '../../components/LiveChat/LiveChat.tsx';

interface Shipment {
  _id: string;
  trackingId: string;
  senderId: string;
  recipientId: string;
  origin: string;
  destination: string;
  deliveryStatus: string;
  estimatedDeliveryDate: string;
  actualDeliveryDate: string;
  sender?: string;
  recipient?: string;
}

interface User {
  _id: string;
  name: string;
  email: string;
  isAdmin: boolean;
}

const UserDashboard: React.FC = () => {
  const [shipments, setShipments] = useState<Shipment[]>([]);
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
  
      try {
        const userResponse = await axios.get('https://api.fastpost.ca/api/profile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const shipmentsResponse = await axios.get('https://api.fastpost.ca/api/shipments', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const userId = userResponse.data._id;
  
        const userShipments = shipmentsResponse.data.filter(
          shipment => shipment.senderId === userId || shipment.recipientId === userId
        );
  
        setUser(userResponse.data);
        setShipments(userShipments);
      } catch (error) {
        console.error('Failed to fetch user data', error);
        navigate('/login');
      }
    };
  
    fetchUserData();
  }, [navigate]);  

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <div className="UserDashboard">
      <h1>User Dashboard</h1>

      {user && (
        <div>
          <h2>User Information</h2>
          <p>Name: {user.name}</p>
          <p>Email: {user.email}</p>
          <p>Is Admin: {user.isAdmin ? 'Yes' : 'No'}</p>
        </div>
      )}

      <h2>Your Shipments</h2>
      <table>
        <thead>
          <tr>
            <th>Tracking ID</th>
            <th>Origin</th>
            <th>Destination</th>
            <th>Delivery Status</th>
            <th>Estimated Delivery Date</th>
            <th>Actual Delivery Date</th>
          </tr>
        </thead>
        <tbody>
          {shipments.map(shipment => (
            <tr key={shipment._id}>
              <td>{shipment.trackingId}</td>
              <td>{shipment.origin}</td>
              <td>{shipment.destination}</td>
              <td>{shipment.deliveryStatus}</td>
              <td>{shipment.estimatedDeliveryDate}</td>
              <td>{shipment.actualDeliveryDate}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <button onClick={handleLogout}>Logout</button>

      <LiveChat />
    </div>
  );
};

export default UserDashboard;
