import React, { useState } from 'react';
import axios from 'axios';
import './Track.css';
import LiveChat from '../../components/LiveChat/LiveChat.tsx';

interface User {
  _id: string;
  name: string;
}


interface Shipment {
  _id: string;
  trackingId: string;
  senderId: string;
  recipientId: string;
  sender?: string;
  recipient?: string;
  origin: string;
  destination: string;
  deliveryStatus: string;
  estimatedDeliveryDate: string;
  actualDeliveryDate?: string;
}

const Track: React.FC = () => {
  const [trackingId, setTrackingId] = useState('');
  const [shipment, setShipment] = useState<Shipment | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(null);
    setShipment(null);

    try {
      const shipmentResponse = await axios.get(`https://api.fastpost.ca/api/shipments/${trackingId}`);
      const shipmentData: Shipment = shipmentResponse.data;
      setShipment(shipmentData);
    } catch (error) {
      console.error('Failed to fetch shipment details', error);
      setError('Failed to fetch shipment details');
    }
  };

  return (
    <div className="track-container">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={trackingId}
          onChange={(e) => setTrackingId(e.target.value)}
          required
        />
        <button type="submit" className="Track-button">Track</button>
      </form>

      {shipment && (
        <div>
          <h2>Shipment Details</h2>
          <ul>
            <li><strong>Tracking ID:</strong> {shipment.trackingId}</li>
            <li><strong>Sender ID:</strong> {shipment.senderId}</li>
            <li><strong>Recipient ID:</strong> {shipment.recipientId}</li>
            <li><strong>Origin:</strong> {shipment.origin}</li>
            <li><strong>Destination:</strong> {shipment.destination}</li>
            <li><strong>Delivery Status:</strong> {shipment.deliveryStatus}</li>
            <li><strong>Estimated Delivery Date:</strong> {new Date(shipment.estimatedDeliveryDate).toLocaleDateString()}</li>
            <li><strong>Actual Delivery Date:</strong> {shipment.actualDeliveryDate ? new Date(shipment.actualDeliveryDate).toLocaleDateString() : 'N/A'}</li>
          </ul>
        </div>
      )}

      {error && (
        <div>
          <h2>Error</h2>
          <p>{error}</p>
        </div>
      )}

    <LiveChat />
    </div>
  );
};

export default Track;