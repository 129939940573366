import React, { useState, useEffect, FormEvent } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import './payment.css';
import LiveChat from '../../components/LiveChat/LiveChat';
import { nanoid } from 'nanoid';

interface PaymentDetails {
    firstName: string;
    lastName: string;
    email: string;
    cardNumber: string;
    expiryDate: string;
    cvv: string;
    cardHolderName: string;
  }

  const Payment: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [paymentDetails, setPaymentDetails] = useState<PaymentDetails>({
      firstName: '',
      lastName: '',
      email: '',
      cardNumber: '',
      expiryDate: '',
      cvv: '',
      cardHolderName: '',
    });

    const { fromAddress, toAddress, packageWeight, distance, date, requestChoice } = location.state || {};

    useEffect(() => {
      const fetchUserData = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
          navigate('/login');
          return;
        }

        try {
          const response = await axios.get('https://api.fastpost.ca/api/profile', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          const { name, email } = response.data;
          const [firstName, lastName] = name.split(' '); 

          setPaymentDetails(prevDetails => ({
            ...prevDetails,
            firstName: firstName,
            lastName: lastName,
            email: email,

          }));
        } catch (error) {
          console.error('Failed to fetch user data', error);
        }
      };

      fetchUserData();
    }, [navigate]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setPaymentDetails(prevDetails => ({
        ...prevDetails,
        [name]: value
      }));
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setPaymentDetails(prevDetails => ({
          ...prevDetails,
          [name]: checked
        }));
      };

      const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
    
        const token = localStorage.getItem('token'); 
    
        try {
    
          const profileResponse = await axios.get('https://api.fastpost.ca/api/profile', {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
    
          const senderId = profileResponse.data._id;
    
          const trackingId = nanoid();
    
          const shipmentData = {
            trackingId,
            senderId,
            origin: fromAddress, 
            destination: toAddress,
            estimatedDeliveryDate: date
          };
    
          const shipmentResponse = await axios.post('https://api.fastpost.ca/api/shipments', shipmentData, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });
    
          const createdShipment = shipmentResponse.data;
          console.log('Shipment created:', createdShipment);
    
          navigate('/dashboard'); 
        } catch (error) {
          console.error('Failed to create shipment', error);
    
        }
      };

  return (
    <div className="payment-page-container">
        <div className="payment-page">
            <form onSubmit={handleSubmit}>
                <div className="info-box">
                    <h3>Personal Information</h3>
                    <input type="text" placeholder="First Name" name="firstName" value={paymentDetails.firstName} readOnly />
                    <input type="text" placeholder="Last Name" name="lastName" value={paymentDetails.lastName} readOnly />
                    <input type="email" placeholder="Email" name="email" value={paymentDetails.email} readOnly />

                </div>
                <div className="info-box">
                    <h3>Payment Details
                        <svg xmlns="http://www.w3.org/2000/svg" width="450" height="2" viewBox="0 0 700 2" fill="none">
                                <path d="M0 1L700 1.00006" stroke="black"/>
                        </svg>
                    </h3>
                    <div>
                        <input
                        type="text"
                        id="cardHolderName"
                        name="cardHolderName"
                        placeholder="Card Holder Name"
                        value={paymentDetails.cardHolderName}
                        onChange={handleChange}
                        required
                        />
                    </div>
                    <div>
                        <input
                        type="text"
                        id="cardNumber"
                        name="cardNumber"
                        placeholder="Card Number"
                        value={paymentDetails.cardNumber}
                        onChange={handleChange}
                        required
                        />
                    </div>
                    <div>
                        <input
                        type="text"
                        id="expiryDate"
                        name="expiryDate"
                        placeholder="Expiry Date"
                        value={paymentDetails.expiryDate}
                        onChange={handleChange}
                        required
                        />
                    </div>
                    <div>
                        <input
                        type="text"
                        id="cvv"
                        name="cvv"
                        placeholder="CVV"
                        value={paymentDetails.cvv}
                        onChange={handleChange}
                        required
                        />
                    </div>
                </div>

                <div>
                    <input
                        type="checkbox"
                        id="confirmTerms"
                        name="confirmTerms"
                        checked={paymentDetails.confirmTerms}
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor="confirmTerms">I agree to the terms and conditions</label>
                </div>
                <button type="submit">Submit Payment</button>
            </form>
        </div>
        <div className="quotation-box-container">
            <div className="quotation-box">
                <h3>Quotation Details</h3>
                <p><strong>From:</strong> {fromAddress}</p>
                <p><strong>To:</strong> {toAddress}</p>
                <p><strong>Service:</strong> {requestChoice}</p>
                <p><strong>Price:</strong> ${(packageWeight * distance / 100).toFixed(2)}</p>
            </div>
        </div>
        <LiveChat/>
    </div>
  );
};

export default Payment;