import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header: React.FC = () => {
  return (
    <header className="Header">
      <nav className='combar'>
        <ul>
          <li>
            <Link to="/">About Us</Link>
          </li>
          <li>
            <Link to="/">FAQ</Link>
          </li>
          <li>
            <Link to="/">Partnership</Link>
          </li>
        </ul>
      </nav>
      <nav className="navbar">
        <div className="left">
          <Link to="/" className="navbar-brand">
            <img src={require('../../assets/BrandLogo.png')} alt="brand logo" width="225px"/>
          </Link>
        </div>
        <div className='right'>
          <ul className="nav">
            <li className="review-item">
              <Link to="/review" className="review-link">Leave a Review</Link>
            </li>
            <li className="contact-item">
              <Link to="/track" className="contact-link">Track Package</Link>
            </li>
            <li className="profile-item">
              <Link to="/register" className="profile-link">
                <img src={require('../../assets/Profile.png')} alt="profile icon" width="20px"/>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;