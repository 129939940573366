import React, { useState, useEffect } from 'react';
import './LiveChat.css';

type MessageType = {
  text: string;
  isUser: boolean;
};

const LiveChat = () => {
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [chatOpen, setChatOpen] = useState(false);
  const [inputMessage, setInputMessage] = useState('');
  const [threadId, setThreadId] = useState<string | null>(null);

  useEffect(() => {
    // Initial welcome message can be omitted since the server will provide it
  }, []);

  const handleChatToggle = () => {
    setChatOpen(!chatOpen);
  };

  const handleServerResponse = (message: string) => {
    setMessages(currentMessages => [...currentMessages, { text: message, isUser: false }]);
  };

  const handleUserMessage = async (message: string) => {
    setMessages(currentMessages => [...currentMessages, { text: message, isUser: true }]);
    setInputMessage('');
    try {
      const response = await sendMessageToChatGPT(message);
      if (response.message) {
        handleServerResponse(response.message);
      }
    } catch (error) {
      console.error('Error sending message to ChatGPT:', error);
    }
  };

  // Send a message to the server and wait for the response
  const sendMessageToChatGPT = async (message: string) => {
    const response = await fetch('https://api.fastpost.ca/api/chat', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message, threadId }),
    });

    if (!response.ok) {
      throw new Error('Error communicating with ChatGPT API');
    }

    const data = await response.json();
    setThreadId(data.threadId);
    return data;
  };

  return (
    <div className="live-chat">
      {chatOpen ? (
        <div className="chat-window">
          <div className="chat-header">
            <h2>Live Chat</h2>
            <div className="chat-status">Connected to support agent</div>
          </div>
          <div className="messages">
            {messages.map((msg, index) => (
              <div key={index} className={msg.isUser ? 'user-message' : 'bot-message'}>
                {msg.text}
              </div>
            ))}
          </div>
          <input
            className="message-input"
            type="text"
            placeholder="Enter your message here"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && inputMessage.trim()) {
                handleUserMessage(inputMessage);
              }
            }}
          />
        </div>
      ) : null}
      <button className="chat-toggle" onClick={handleChatToggle}>
        ?
      </button>
    </div>
  );
};

export default LiveChat;